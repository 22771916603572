import React from 'react';
import './Pricing.css';

const { Zoom } = require("react-reveal");
const data = [
  // USD 
  { SoftwareName: "AGS License Manager", OneMonthLicense:"$39 USD", SixMonthLicense: "$234 USD", FullYear: "$468 USD", Purchase: "Subscription Only", TwentyOnePercent:"Subscription Only" },
  { SoftwareName: "Credit Rec", OneMonthLicense:"$100 USD", SixMonthLicense: "$600 USD", FullYear: "$1200 USD", Purchase: "$2295 USD", TwentyOnePercent:"$481.95 USD", Notes:'*See Note' },
  { SoftwareName: "Credit Rec Additional User", OneMonthLicense:"$20 USD", SixMonthLicense: "$120 USD", FullYear: "$240 USD", Purchase: "$595 USD", TwentyOnePercent:"$124.95 USD" },
  { SoftwareName: "IC Count", OneMonthLicense:"$10 USD", SixMonthLicense: "$60 USD", FullYear: "$120 USD", Purchase: "Subscription Only", TwentyOnePercent:"Subscription Only", },
  { SoftwareName: "QuickBase 2 Sage 300", OneMonthLicense:"$300 USD", SixMonthLicense: "$1800 USD", FullYear: "$3600 USD", Purchase: "$6995 USD", TwentyOnePercent:"$1468.95 USD", Notes:'*See Note' },
  { SoftwareName: "PDFTOEXCEL", OneMonthLicense:"Not Available ", SixMonthLicense: "Not Available", FullYear: "$595 USD", Purchase: "Subscription Only", TwentyOnePercent:"Subscription Only" },
  { SoftwareName: "TB Tools", OneMonthLicense:"$350 USD ", SixMonthLicense: "Not Available", FullYear: "Not Available", Purchase: "Subscription Only", TwentyOnePercent:"Subscription Only" },
  { SoftwareName: "Wireless Merchant Server", OneMonthLicense:"Not Available", SixMonthLicense: "$247.5 USD", FullYear: "$595 USD", Purchase: "Subscription Only", TwentyOnePercent:"Subscription Only" },
  { SoftwareName: "Wireless Merchant Web Client ", OneMonthLicense:"Free With Server", SixMonthLicense: "Free With Server", FullYear: "Free With Server", Purchase: "Subscription Only", TwentyOnePercent:"Subscription Only" },
  { SoftwareName: "Wireless Merchant Android Client", OneMonthLicense:"$40 USD", SixMonthLicense: "$231 USD", FullYear: "$462 USD", Purchase: "Subscription Only", TwentyOnePercent:"Subscription Only" },
]
const cad = [
// CANADIAN 
  { SoftwareNameCanadian: "AGS License Manager", OneMonthLicenseCanadian:"$39 CAD", SixMonthLicenseCanadian: "$234 CAD", FullYearCanadian: "$468 CAD", PurchaseCanadian: "Subscription Only", TwentyOnePercentCanadian:"Subscription Only" },
  { SoftwareNameCanadian: "Credit Rec", OneMonthLicenseCanadian:"$100 CAD", SixMonthLicenseCanadian: "$600 CAD", FullYearCanadian: "$1200 CAD", PurchaseCanadian: "$2295 CAD", TwentyOnePercentCanadian:"$481.95 CAD", Notes:'*See Note' },
  { SoftwareNameCanadian: "Credit Rec Additional User", OneMonthLicenseCanadian:"$20 CAD", SixMonthLicenseCanadian: "$120 CAD", FullYearCanadian: "$240 CAD", PurchaseCanadian: "$595 CAD", TwentyOnePercentCanadian:"$124.95 CAD" },
  { SoftwareNameCanadian: "IC Count", OneMonthLicenseCanadian:"$10 CAD", SixMonthLicenseCanadian: "$60 CAD", FullYearCanadian: "$120 CAD", PurchaseCanadian: "Subscription Only", TwentyOnePercentCanadian:"Subscription Only" },
  { SoftwareNameCanadian: "QuickBase 2 Sage 300", OneMonthLicenseCanadian:"$300 CAD", SixMonthLicenseCanadian: "$1800 CAD", FullYearCanadian: "$3600 CAD", PurchaseCanadian: "$6995 CAD", TwentyOnePercentCanadian:"$1468.95 CAD", Notes:'*See Note' },
  { SoftwareNameCanadian: "PDFTOEXCEL", OneMonthLicenseCanadian:"Not Available ", SixMonthLicenseCanadian: "Not Available", FullYearCanadian: "$595 CAD", PurchaseCanadian: "Subscription Only", TwentyOnePercentCanadian:"Subscription Only" },
  { SoftwareNameCanadian: "TB Tools", OneMonthLicenseCanadian:"$350 CAD ", SixMonthLicenseCanadian: "Not Available", FullYearCanadian: "Not Available", PurchaseCanadian: "Subscription Only", TwentyOnePercentCanadian:"Subscription Only" },
  { SoftwareNameCanadian: "Wireless Merchant Server", OneMonthLicenseCanadian:"Not Available", SixMonthLicenseCanadian: "$247.5 CAD", FullYearCanadian: "$595 CAD", PurchaseCanadian: "Subscription Only", TwentyOnePercentCanadian:"Subscription Only" },
  { SoftwareNameCanadian: "Wireless Merchant Web Client ", OneMonthLicenseCanadian:"Free With Server", SixMonthLicenseCanadian: "Free With Server", FullYearCanadian: "Free With Server", PurchaseCanadian: "Subscription Only", TwentyOnePercentCanadian:"Subscription Only" },
  { SoftwareNameCanadian: "Wireless Merchant Android Client", OneMonthLicenseCanadian:"$40 CAD", SixMonthLicenseCanadian: "$231 CAD", FullYearCanadian: "$462 CAD", PurchaseCanadian: "Subscription Only", TwentyOnePercentCanadian:"Subscription Only" },
]
 
function Pricing() {
  return (
    <div>
      <Zoom>
      
      <h1 className='pricing-heading'>Product Pricing International Clients billed in USD</h1>
      <div className="pricing_container">
      <table className='pricing-table'>
        <tr>
          <th  className='pricing-th'>Software Name</th>
          <th className='pricing-th'>30 Day License <br/>Subscription </th>
          <th className='pricing-th'>6 Month License <br/> Subscription</th>
          <th className='pricing-th'>Yearly License <br/> Subscription</th>
          <th className='pricing-th'>Purchase <br/> License</th>
          <th className='pricing-th'>21% Annual  <br/> Software Assurance</th>
          <th className='pricing-th'>See Notes</th>
        </tr>
        
        {data.map((val, key) => {
          return (
            <tr key={key}>
              <td className='pricing-td'><b> {val.SoftwareName}</b></td>
              <td className='pricing-td' style={{color:'#11abed'}}>{val.OneMonthLicense}</td>
              <td className='pricing-td' style={{color:'#11abed'}}>{val.SixMonthLicense}</td>
              <td className='pricing-td' style={{color:'#11abed'}}>{val.FullYear}</td>
              <td className='pricing-td'  style={{color:'#11abed'}}>{val.Purchase}</td>
              <td  className='pricing-td' style={{color:'#11abed'}}>{val.TwentyOnePercent}</td>
              <td className='pricing-td' style={{color:'#11abed'}}>{val.Notes}</td>
            </tr>
          )
        })}
      </table>
      </div>
      </Zoom>
      <Zoom>
      <p className='pricing-text'><strong>*Note:</strong>Credit Rec and QuickBase to Sage modules can be either a subscription model or purchase with annual software assurance (license expires either way after one year)</p>
      <p className='pricing-text'>**PLEASE NOTE ALL PRICING ARE FOR SINGLE USERS ONLY**</p>
      <p className='pricing-text'>***ALL LICENSES ARE BILLED EITHER 6 MONTH OR 12 MONTH CONTRACTS ONLY***</p>
      <p className='pricing-text'>****TB Tools 30 day fee is a one time fee and one time use for 30 days only from start of license****</p>
      <br />
      </Zoom>
      <Zoom>
      <h1 className='pricing-heading'>Product Pricing Canadian Clients billed in CAD</h1>
      <div className="pricing_container">
      <table className='pricing-table'>
        <tr>
          <th className='pricing-th'>Software Name</th>
          <th  className='pricing-th' >30 Day License <br/>Subscription </th>
          <th  className='pricing-th'>6 Month License <br/> Subscription</th>
          <th  className='pricing-th'>Yearly License <br/> Subscription</th>
          <th  className='pricing-th'>Purchase <br/> License</th>
          <th  className='pricing-th'>21% Annual  <br/> Software Assurance</th>
          <th  className='pricing-th'>See Notes</th>
        </tr>
        {cad.map((val, key) => {
          return (
            <tr key={key}>
              <td  className='pricing-td'><b> {val.SoftwareNameCanadian}</b></td>
              <td className='pricing-td' style={{color:'#11abed'}}>{val.OneMonthLicenseCanadian}</td>
              <td  className='pricing-td' style={{color:'#11abed'}}>{val.SixMonthLicenseCanadian}</td>
              <td  className='pricing-td' style={{color:'#11abed'}}>{val.FullYearCanadian}</td>
              <td  className='pricing-td' style={{color:'#11abed'}}>{val.PurchaseCanadian}</td>
              <td  className='pricing-td' style={{color:'#11abed'}}>{val.TwentyOnePercentCanadian}</td>
              <td  className='pricing-td' style={{color:'#11abed'}}>{val.Notes}</td>
            </tr>
          )
        })}
      </table>
      </div>
      <p className='pricing-text'><strong>*Note:</strong>Credit Rec and QuickBase to Sage modules can be either a subscription model or purchase with annual software assurance (license expires either way after one year)</p>
      <p className='pricing-text'>**PLEASE NOTE ALL PRICING ARE FOR SINGLE USERS ONLY**</p>
      <p className='pricing-text'>***ALL LICENSES ARE BILLED EITHER 6 MONTH OR 12 MONTH CONTRACTS ONLY***</p>
      <p className='pricing-text'>****TB Tools 30 day fee is a one time fee and one time use for 30 days only from start of license****</p>  
      </Zoom>
    </div>
  );
}

export default Pricing
