import React, { useEffect } from "react";
import { Tabs } from 'antd';
import ('./dashobard.css')
const { TabPane } = Tabs;
let Dashboard =(props)=>{
    let payload=JSON.parse(localStorage.getItem("license-expiry"))
    return(
        <div >
          <Tabs centered defaultActiveKey="1">
    <TabPane tab="Downloads" key="1">
        === Downloads ====
    </TabPane>
    
    <TabPane tab="Settings" key="3">
      Tab 3
    </TabPane>
    <TabPane tab="License Details" key="4">
      <div className="license-card">
       
        <h2><pre>license Code    {payload.data.code}</pre></h2>
        <h2><pre>Expire Date     {payload.data.expire_date}</pre> </h2>
        <h2><pre>Message  {payload.message}</pre></h2>
        <form>
        <input className='login_submit_btn'  type={"button"} value="Logout" onClick={()=>{
            props.status(null)
            localStorage.setItem("license-status",null)
            localStorage.setItem("license-expiry",null)
        }} />

        </form>
      </div>
    </TabPane>
  </Tabs>
        </div>
    )
}

export default Dashboard