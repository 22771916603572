import("./wirelessMarchant.css");
import("./license.css");
import("./credit.css");
import('./ICCount.css');
import('./Quickbasetosage.css');
import('./pdf2excel.css');
import('./tbtools.css');


const { Zoom } = require("react-reveal");
let wirelessLogo = require("../wireless.png");
let CreditLogo = require("../CreditCardLogo.png");
let WirelessShort_hand = require("../WirelessShorthand.png");
let Bixolon = require("../bixolon.png");

let CreditWebShort = require("../CreditWebShort.png");
let wirelessServer = require("../wirelessServer.png");

let LicenseShort = require("../LicenseShort.png");
let PDFTOEXCELLOGO = require("../PDFTOEXCELLOGO.png");
let PDFShort = require ("../PDFShort.png");

let QuickBaseLogo = require ("../QuickBaseLogo.png") 
let QuickBaseFeature = require ("../QuickBaseFeature.png");
let QuickBaseService = require ("../QucikBaseServices.png");



// Wireless Marchant 
export const data = [
  {
    code: "wireless-merchant",
    body: (
      <div className="wirelessMarchant">
        <div>
          
        </div>
        <div className="wirelesshead">

          <img className="wirelessTop" src={wirelessLogo} alt='Wireless' />
          <img src={WirelessShort_hand} alt='wireless Screen Short' className="wireless_hand_pic" />
        </div>

        <p className="paragraph">
          Wireless Merchant turns your mobile smartphone or tablet into a mini
          Sage 300c Order Entry system. You can process Quotes, Orders, Invoices
          and even Credit Notes all from the palm of your hands. Wireless
          Merchant takes advantage of the Sage 300c API technology to link
          seamlessly to and from your Sage 300 database, therefore all of your
          customer profiles, price lists and items are all the same on your
          device. Add images to each item for a virtual catalog to showcase to
          potential clients, connect live to Sage 300 for price lookup, stock
          check availability, customer outstanding order shipment, customer
          outstanding invoice balances.", Wireless Merchant also has a full
          Payment Processing add-on module that allows you to accept payment and
          apply to outstanding balances against customers account. Accept cash,
          cheque and even credit cards with this payment module.",
          description_three:"Wireless Merchant Server Processor connects your
          mobile devices to Sage 300c securely with AES encryption to ensure
          that your data is always protected. The WM Server Processor allows you
          to integrate to multiple Sage 300c databases to allow you to deploy
          your workers to the appropriate company database. There are many
          features to the Server Processor that also allows you to control what
          each device can process and filter such as specific items, category
          codes, customers by territory, transaction type and many more."
        </p>
        <Zoom>
          <h1 className="WM-list-heading">Features and Devices</h1>

          <div className="server">
            <div className="server-features">
              <ol className="WM-list-view">
                <li>Process Quotes, Orders, Invoices & Credit Notes </li>
                <li> Accept on Account Customer Payments </li>
                <li> Nuvei Credit Card Processing (coming soon) </li>
                <li> Live Inventory Stock & Price Checking </li>
                <li>
                  {" "}
                  Live Order Status Checking, see if past orders are incomplete
                  or shipped
                </li>
                <li>
                  {" "}
                  Email Customer confirmation of Quotes, Orders Invoices or
                  Credit Notes
                </li>
                <li> Live Customer Outstanding Balances & Invoice look up.</li>
                <li>Dynamic image attachment to Items for Virtual Catalogue</li>
                <li>
                  Online or Offline transaction processing sync later when
                  signal is in range
                </li>
                <li>
                  {" "}
                  Bluetooth Printers and Bar Code Scanners support ( see list
                  under peripherals)
                </li>
              </ol>
              <img className="wirelessinside-img" src={Bixolon} alt="Bixolon" />
            </div>
          </div>
        </Zoom>
        <h1 className="WM-list-heading">Server Processor</h1>
        <Zoom>
          <div className="server">
            <ol className="WM-list-view">
              <li> Select Sage 300c version for integration</li>
              <li> Add Filters for Customers, Price Lists, Categories</li>
              <li>
                Devices management with added filters and full customization of
                processing power
              </li>
              <li>
                {" "}
                SQL email setup for error management with unfulfilled
                transactions to Sage 300c
              </li>
              <li> Auto Over Credit Limit Approval Processing</li>
              <li>Auto Set Over Credit Limit Orders on Hold</li>
              <li> Full Sage 300c Optional Field Customs</li>
              <li>
                Set auto message to specific categories and items for sales and
                marketing
              </li>
              <li>
                Set Transaction Type Numbering to sync and differentiate each
                device transactions
              </li>
              <li>Set Payment types to sync with Sage 300c</li>
              <li>
                Full device processing logs, see everything each devices sends
                and receives from Server Processor
              </li>
              <li>
                {" "}
                Web Console for internal or external management of WM Server
                Processor Transaction Management
              </li>
            </ol>
            <img
              className="wireless-server-img"
              src={wirelessServer}
              alt="wireless"
            />
          </div>
          <div></div>
        </Zoom>

        <h1 className=" WM-list-heading animate__animated animate__bounce animate__faster">
          Requirements/Compatibility
        </h1>
        <Zoom>
          <table className="req">
            <tr>
              <td className="wireless-td" >Desktop/Server</td>
              <td className="wireless-td">
                Windows Compatible PC P3 or higher. Dedicated Internet
                Connection and Static IP, Router and Port forwarding
                Capabilities
              </td>
            </tr>
            <tr>
              <td className="wireless-td">Software</td>
              <td className="wireless-td">
                Windows 10 or higher, Server 2012 or higher (depends on Sage
                300c version), Microsoft SQL 2012 or higher (depends on Sage
                300c version)
              </td>
            </tr>
            <tr>
              <td className="wireless-td" >Sage 300c</td>
              <td className="wireless-td">
                Sage 2012 (v6.1), Sage 2014(v6.2), Sage 2016(v6.3), Sage
                2017(v6.4), Sage 2018(v6.5), Sage 2019(v6.6), Sage 2020(v6.7),
                Sage 2021(v6.8), Sage 2022(v6.9)
              </td>
            </tr>
            <tr>
              <td className="wireless-td">Devices</td>
              <td className="wireless-td">
                Android Smartphone & Tablets – OS 5.0 or above Apple iPhone
                Smartphone & Tablets – OS 9.0 or above
              </td>
            </tr>
            <tr>
              <td className="wireless-td">Payments</td>
              <td className="wireless-td">Nuvei Processing (coming soon)</td>
            </tr>
          </table>

          <div className="wireless-video-container">
            <iframe
              className="wireless-ytplayer"
              title="WirelessMarchant"
              alt="https://www.youtube.com/embed/nA0AW4xGebw"
              type="text/html"
              src="https://www.youtube.com/embed/nA0AW4xGebw"
            ></iframe>
            <iframe
              className="wireless-ytplayer"
              title="WirelessMarchant"
              alt=""
              type="text/html"
              src="https://www.youtube.com/embed/SfyhoHu0Xfc"
            ></iframe>
            <iframe
              className="wireless-ytplayer"
              title="WirelessMarchant"
              type="text/html"
              src="https://www.youtube.com/embed/8JMO3FpfyMs"
            ></iframe>
          </div>
        </Zoom>
      </div>
    ),
  },

  //License Manager 
  {
    code: "license-mgmt",
    body: (
      <div className="license">
        <Zoom>
          <div className="licensehead">
            <div className="licenseMainimg">
              <img
                src={process.env.PUBLIC_URL+'\\LicenseLogo.svg'}
                className="License-Logo"
                alt="License Logo"
                title="AGS License"

              />
              <img
                src={LicenseShort}
                className="License-short"
                alt="License Screen Short"
                title="AGS License Screen Short"
              />
            </div>
          </div>
        </Zoom>
        <Zoom>
          <p className="paragraph">
            AGS License Manager is a cloud based software and SAAS model that
            enables developers and businesses to add and manage licenses for their
            Mobile, desktop or Cloud based software. With our easy to use API
            and our cloud management tool, get started selling your software
            instead of managing it. Never miss another payment for your software
            with our date expiration options. Manage clients and their licenses,
            type of devices, software versions and more.
          </p>
        </Zoom>

        <h1 className="License-list-heading">Features and Benefits</h1>
        <div className="license-server">
          <Zoom>
            <ol className="License-list-view">
              <li>
                API provided to imbed license keys easily into your applications
              </li>
              <li> Auto key code generator enabled for license key </li>
              <li>
                {" "}
                Set keys with expiry dates for monthly, quarterly, semi-annual
                or annual license contracts
              </li>
              <li>
                {" "}
                Manages all clients with all their devices and software licenses
                with expiry dates
              </li>
              <li> Run reports on upcoming renewals</li>
              <li>Ability to reset licenses in case of device switch</li>
              <li> Saves time and money on license development costs</li>
              <li>Simple to implement and manage</li>
            </ol>
            <img
              src={process.env.PUBLIC_URL+'\\LicenseFeature.png'}
              alt="img not found"
              className="lecense-inside-img"
            />
          </Zoom>
        </div>
        <div>
          <Zoom>
            <h1 className="License-list-heading">
              Requirement and Compatibility
            </h1>
            <ol className="License-list-view">
              <li>Any computer connected to the internet</li>
              <li> Ability to use API into your coding</li>
              <li> Android 5.0 or above for Mobile Access on Androids</li>
              <li> iOS 9.0 or above for Mobile Access on Apple</li>
            </ol>
          </Zoom>
        </div>
        <Zoom>
        <div className="license-video-container">
            <iframe
              className="license-ytplayer"
              title="Credit Rec Software Use"
              alt="https://www.youtube.com/embed/gZzSdXFgU8A"
              type="text/html"
              src="https://www.youtube.com/embed/gZzSdXFgU8A"
            ></iframe>
            </div>
            </Zoom>
      </div>
    ),
  },


  // Credit Rec
  {
    code: "credit-rec",
    body: (
      <div className="credit-rec">
        <div className="credit-head">
            <div className="creditMain-img">
              <img src={CreditLogo} className="credit-logo" alt="credit logo" />
              <img
                src={CreditWebShort}
                className="credit-short"
                alt="credit Screen Short"
              />
            </div>
        </div>
        <p className="paragraph">
          Credit Rec is a desktop application that allows businesses to manage
          Credit Cards Spending and reconciliation to Credit Card Statements.
          Manage each transaction and end proper General Ledger account for
          expenditures and asset purchases from any and all Credit Cards. Manage
          your fleet Credit Cards for employees, know your balance at any time.
          Use one Credit Card for multiple companies, no problem, Credit Rec
          allows you to connect to multiple database in a single batch or entry.
        </p>
        <h1 className="Credit-list-heading">Features</h1>
        <div className="credit-server">
          <Zoom>
            <ol className="Credit-list-view">
              <li> Process all Credit Card Expenses </li>
              <li> Process Payment to Sage AP Invoices via Credit Cards </li>
              <li> Manage all Credit Cards</li>
              <li> Process Payments to Credit Cards </li>
              <li>
                {" "}
                Reconcile Each Credit Card Transaction to your Credit Card
                Statement{" "}
              </li>
              <li> Manage Multi Currency Transactions on your Credit Card </li>
              <li>
                {" "}
                Apply Credit Card Charges to multiple Sage company databases in
                same batch
              </li>
              <li>
                {" "}
                Split Credit Card Expenses within multiple Sage companies{" "}
              </li>
              <li>
                {" "}
                Send all transactions to Sage 300 General Ledger Journal
                Entries, Accounts Payable Transactions and Bank Services
                Transactions
              </li>
              <li>
                {" "}
                Web Management Tool to approve or disapprove expenses (coming
                soon)
              </li>
              <li>
                {" "}
                Mobile Expense entry with receipt images sending to Web
                Management (coming soon)
              </li>
              <li> Send all Web/Mobile transactions to desktop Credit Rec</li>
              <li>
                
                Print Reconciliation Reports, transaction reports and payment
                reports.
              </li>
            </ol>
            <img
              src={process.env.PUBLIC_URL+'\\CreditFeature.png'}
              className="credit-inside-img"
              alt="Credit Feature"
            />
          </Zoom>
        </div>

        <h1 className="Credit-list-heading">Features and Devices</h1>
        <div className="credit-feature">
          <Zoom>
            <ol className="Credit-list-view">
              <li> Windows 10 or above for workstations</li>
              <li> Windows 2014 or above Server Software</li>
              <li> Microsoft SQL 2016 or above</li>
              <li>Microsoft IIS If running Credit Rec Web Tool and Mobile App</li>
              <li> Approximately 200meg of disk space for software</li>
              <li>
                Internet connectivity and router with port forwarding if using
                Credit Rec Web Tool and Mobile App
              </li>
              <li>If Integrating with Sage 300c compatible with 2019(v6.6),
                2020(v6.7), 2021(v6.8) and 2022(v6.9) versions
              </li>
              <li> Android 5.0 or above for Mobile App on any Android device</li>
              <li> iOS 9.0 or above for Mobile App on Apple devices</li>
            </ol>
            <img
              src={process.env.PUBLIC_URL+'\\CreditDevices.png'}
              className="credit-feature-img"
              alt="credit-feature-img"
              title="Credit Device"
            />
          </Zoom>
        </div> 
        <Zoom>
        <div className="credit-video-container">
            <iframe
              className="credit-ytplayer"
              title="Credit Rec Software Use"
              alt="https://www.youtube.com/embed/t6pB-B2vqro"
              type="text/html"
              src="https://www.youtube.com/embed/t6pB-B2vqro"
            ></iframe>
            </div>
            </Zoom>
      </div>
    ),
  },

  // IC COUNT
  {
    code: "ic-count",
    body: (
      <div className="ic-count">
        <Zoom>
          <div className="ic-count-head">
            <div className="ic-count-head-text">
              <h3 className="ic-count-title">Simplify your sage 300 Inventory counts with AGS IC Count</h3>
            </div>
            <div className="ic-count-logo-class">
            <img src={process.env.PUBLIC_URL+'\\ICCountLogo.svg'} className="ic-count-logo" alt="IC Count" />
            </div>
          </div>
        </Zoom>
        <Zoom>
        <div className="ic-count-paragraph-class">
        <p className="paragraph">
        IC Count is a mobile application for Android and iOS that allows you to manually enter or scan
         (via Bluetooth scanner) your items and enter the quantity based on Item number/description/Unit of Measure. 
         Once you have completed your count you can email a copy as an excel file to review your entries,
          email a copy and or complete the process and send directly to Sage 300 Inventory Physical Worksheet or
           as an Inventory Adjustment depending on your requirements.
        </p>
        <p className="paragraph">
        IC Count will allow you to quick spot check counting of Inventory 
        so that you can keep an eye on your available quantities and make sure your Inventory is always up to date. 
        Take counts based on Category Codes, Picking/Bin Sequence or just by Item number, 
        Inventory taking has never been easier than with IC Count.
        </p>
        </div>
        </Zoom>
         
          <h1 className="ic-count-list-heading">Features</h1>
         <div className="ic-count-feature">
         <div className="credit-feature">
          <Zoom>
            <ol className="ic-count-list-view">
              <li>	Bluetooth Scanning of Items </li>
              <li> 	Manual Date Entry </li>
              <li> 	Scan by Category Codes, range or specific categories for quick spot checks</li>
               <li>Scan by Picking/Bin Sequence, range or specific Picking/Bin for quick spot checks</li>
               <li>Send directly to Sage 300 Physical Inventory Worksheet or Send directly to Sage 300 Inventory Adjustment Entry </li>
              <li>	Print Inventory Count Sheet from Device</li>
              <li>	Email Inventory Count Sheet as an Excel file for review </li>
              <li> Submit Inventory Count Directly to Sage 300 for review and posting</li>
            </ol>
            </Zoom>
            </div>
         </div>
         
          <h1 className="ic-count-list-heading">
            Requirements/Compatibility 
          </h1>
          <div className="ic-count-requirements-text">
          <p><strong>Software:</strong>
          : Windows 10 or higher, Server 2012 or higher (depends on Sage 300c version),
           Microsoft SQL 2012 or higher (depends on Sage 300c version)
          </p>
          <p><strong>Sage 300:</strong>
          Sage 2019(v6.6), Sage 2020(v6.7), Sage 2021(v6.8), Sage 2022(v6.9)
          </p>
          <p><strong>Mobile Device:</strong> 
          Android Smartphone & Tablets – OS 5.0 or above Apple iPhone Smartphone & Tablets – OS 9.0 or above
          </p>
          <p><strong>Bluetooth Scanner: </strong>
          Any Bluetooth compatible keyboard wedge style scanner 
          (meaning must be able to scan in any application on your device and display what was scanned)
          </p>
          <p><strong>Preferred Scanner: </strong>
          Any Opticon mobile bluetooth scanner.
          </p>
          </div>
        
      </div>
    ),
  },
  // Quick Base 2 Sage 300
  {
    code: "QuickBase2Sage300",
    body: (
      <div className="quickbasetosage">
        <div className="quick-heading">
        <img src={QuickBaseLogo} className="QuickBase-logo" alt="QuickBase To Sage" />
        </div>
        <Zoom>
        <div className="quickbasetosage-paragraph-div">
          <p className="paragraph">
          QuickBase to Sage 300 is a seamless integration software that 
          allows users to Send and Receive Accounts Payable Invoice/Payments, 
          Accounts Receivable Invoice/Receipts and Payroll Timecards from QuickBase into Sage 300 
          and back. This enables managers to manage their budgets for projects and know at any time
           what has been processed by accounting and what is still outstanding. While taking full 
           advantage of 
          QuickBase processing and still maintaining the Sage 300 accounting integrity.
          </p>
        </div>
        </Zoom>
        <h1 className="ic-count-list-heading">Features</h1>
         <div className="ic-count-feature">
         <div className="credit-feature">
          <Zoom>
            <ol className="ic-count-list-view">
              <li>	Send Accounts Payable Invoices from QuickBase to Sage</li>
              <li> 	Send Accounts Payable Credit Notes from QuickBase to Sage </li>
              <li> 	Send Accounts Payable Payment from Sage to QuickBase</li>
              <li>  Send Accounts Receivable Invoice information for Invoice generation from QuickBase to Sage</li>
              <li> 	Send Accounts Receivable Invoice Number from Sage to QuickBase	</li>
              <li>	Send Accounts Receivable Receipts from Sage to QuickBase</li>
              <li>	Send Payroll Timecards from QuickBase to Sage for processing Payroll	</li>
              <li>	Automated option for transactions to upload into Sage and push back to QuickBase as specific times. </li>
              <li>	Auto receive successful or error notification via email after upload completed</li>
            </ol>
            <img src={QuickBaseFeature} alt="QuickBaseFeature" className="QuickInsideFeature"/>
            </Zoom>
            </div>
         </div>
         
        <h1 className="ic-count-list-heading">
            Requirements/Compatibility 
          </h1>
          <div className="ic-count-requirements-text">
          <img src={QuickBaseService} alt="QuickBaseService" className="QuickBaseInsideService" />
          <p><strong>Software:</strong>
          : Windows 10 or higher, Server 2012 or higher (depends on Sage 300c version),
           Microsoft SQL 2012 or higher (depends on Sage 300c version)
          </p>
          <p><strong>Sage 300:</strong>
          Sage 2019(v6.6), Sage 2020(v6.7), Sage 2021(v6.8), Sage 2022(v6.9)
          </p>
          <p><strong>Software:</strong> 
          Windows 10 or higher, Server 2012 or higher (depends on Sage 300c version), Microsoft SQL 2012 or higher (depends on Sage 300c version)
          </p>
          <p><strong>Sage 300: </strong>
          Sage 2019(v6.6), Sage 2020(v6.7), Sage 2021(v6.8), Sage 2022(v6.9) (with Optional Fields module)
          </p>
          <p><strong>QuickBase: </strong>
          any version of QuickBase with specific file format (provided) to send and receive from Sage 300
          </p>
          </div>
          </div>
    ),
  },

// pdf to excel 

  {
    code: "pdftoexcel",
    body: (
      <div className="pdftoexcel">
       <div className="pdftoexcel-head">
        <div className="pdftoexcel-head-text">
          <h1 className="pdftoexcel-head-title">Streamline Government PDF RFP Document Processing </h1>
        </div>
        <div className="pdftoexcel-logo">
        <img src={PDFTOEXCELLOGO} className="pdflogo" alt="PDF TO EXCELS" />
        </div>
       </div>
       <div className="pdf-to-excel-paragraph-div">
        <p className="paragraph">
        PDF2Excel is a simple tool that allows you to process any PDF document
         into an Excel file with Item and Quantity counts displaying into a simple file 
         to count what you need to create your RFP.  Reduce your processing time from days 
         into minutes so that your quantity count for each item is ready 
        for you to create your RFP quicker and faster than anyone else.
        </p>
       </div>
       <h1 className="ic-count-list-heading">Features</h1>
         <div className="ic-count-feature">
         <div className="credit-feature">
          <Zoom>
            <ol className="ic-count-list-view">
            <li>Convert PDF files to Excel Files</li>
            <li>Convert all different items and quantity to single lines </li>
            <li>Fast processing time to create your RFP item and quantities in seconds not days</li>
            <li>Review and process hundreds of PDF pages in seconds</li>
            <li>Simplifies the RFP process from any PDF item and quantities</li>
            </ol>
            <img
              src={PDFShort}
              className="pdf-inside-img"
              alt="img not found"
            />
            </Zoom>
            </div>
         </div>
        <h1 className="ic-count-list-heading">
            Requirements/Compatibility 
          </h1>
          <div className="ic-count-requirements-text">
          <p><strong>Software:</strong>
          Microsoft Excel, PDF Reader
          </p>
          <p><strong>Computer:</strong>
          Microsoft Windows 10 or above PC
          </p>
          </div>
      </div>
    ),
  },

  // AGS TB Tool
  {
    code: "ags-TB-import-tools",
    body: (
      <div className="tbtool">
        <Zoom>

        
        <div className="tb-tool-main-text">
          <h1 className="tb-tool-title-M-text">Automate Sage 300 Opening Balances from  Any other Accounting System </h1>
        
          <div className="tb-tool-head">
          <img src={process.env.PUBLIC_URL+'.\\TBLogo.png'} alt="AGS TB Import Tools" className="TBLogo" />
        </div>        </div>
        </Zoom>
         <div className="tb-paragraph-div">
          <Zoom>
          <p className="paragraph">
          AGS TB (Trial Balance) Tools is an online tool that allows you to create an import 
          file for General Ledger, Accounts Payable and Accounts Receivable opening balances
           and detail transactions. Creating a new company? Moving to Sage 300 from another 
           accounting system? No problem, TB Tools you covered. From excel export files from 
           your current system, TB Tools will create proper Sage 300 import files for GL Trial Balance,
            Accounts Payable Detail Invoice balances and Accounts 
          Receivable Detail Invoice balances to match your old system Trial Balances. 
          </p>
          </Zoom>
         </div>
         <Zoom>
         <h1 className="ic-count-list-heading">Features</h1>
         <div className="ic-count-feature">
         <div className="credit-feature">
          
            <ol className="ic-count-list-view">
            <li>Create Import files from excel files exported from other systems</li>
            <li>Create detailed Accounts Receivable Opening Trial Balance Transactions import file.</li>
            <li>Create detailed Accounts Payable Opening Trial Balance Transactions import file.</li>
            <li>Create detailed General Ledger Opening Trial Balance Amount import file.</li>
            <li>Simplify your data conversion process in minutes not days.</li>
            </ol>
            </div>
         </div>
         </Zoom>
         <Zoom>
         <h1 className="ic-count-list-heading">
            Requirements/Compatibility 
          </h1>
          <div className="ic-count-requirements-text">
          <p><strong>Software:</strong>
          Windows 10 or higher, Server 2012 or higher (depends on Sage 300c version),
           Microsoft SQL 2012 or higher (depends on Sage 300c version) Internet connectivity
          </p>
          <p><strong>Sage 300:</strong>
          Sage 2019(v6.6), Sage 2020(v6.7), Sage 2021(v6.8), Sage 2022(v6.9) 
          </p>
          </div>
         </Zoom>
      </div>

    ),
  },
];
