import React, { useEffect,  useState } from "react";
import { useParams,useNavigate } from "react-router-dom";

import './Solution.css';
import { Zoom } from 'react-reveal';
import {  Modal  } from 'antd';
let data=require('./AllDropdown')
let licenseImage = require("../modal.png");

const Solution = () => {
  let navigate = useNavigate();
  let { solutionID } = useParams();
  let [obj,setObj]=useState([])
  const [visible, setVisible] = useState(false);
  useEffect(()=>{ 
   setObj(data.data.find(element=>element.code===solutionID))
  })
  useEffect(()=>{ 
    if(solutionID==='license-mgmt'){
       console.log('license')
       setVisible(true)
    }
   },[])
 
  return (
    <Zoom>
    <div>
    {
      obj.body
      
    }
      </div>  
    <div>
    <Modal
        title="Modal 1000px width"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
       
      >
      
       <div className="modal-body">
        <div className="modal-badge">
        <h1>License SAAS Software By AGS Advanced Inc.</h1>  
        <img alt='something wrong' src={licenseImage}/> 
        </div>
       <ol className="license-modal">
        <li>Cloud-based Software License</li>
        <li>Customer Identity Management </li>
        <li>API for license Integration  </li>
        <li>License Usage Insight  </li>
       </ol>
       <div className="modal-btn">
        <button onClick={()=>{
          navigate('/contact')
        }}>
          Schedule A demo
        </button>
        <button onClick={()=>{
          setVisible(false)
        }}>
          Learn a more
        </button>
       </div>
       <div>

       </div>
       </div>
      </Modal>
    </div>
  
    </Zoom>
    
  );
};
export default Solution;
