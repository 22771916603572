import React from 'react'
import { Zoom } from 'react-reveal';
import {Link} from 'react-router-dom';
import './LandingPage.css'

const LandingPage = () => {
  return (
    <Zoom>
      
    <div className="LandingPage-images">
     <div>
        <Link to="/solution/wireless-merchant">
      <img src={process.env.PUBLIC_URL+'\\wireless.png'} alt="wireless"   />
      </Link> 
      <h2 className="h2">Mobile  Sales </h2>
      </div>
      <div>
      <Link to="/solution/ic-count">
      <img  src={process.env.PUBLIC_URL+'\\ICCountLogo.svg'} alt="wireless"   />
      </Link> 
      <h2 className="h2">Inventory   Counts  </h2>
      </div>
      <div>
      <Link to="/solution/credit-rec">
      <img src={process.env.PUBLIC_URL+'\\CreditCardLogo.png'} alt="wireless"   />
      </Link> 
      <h2 className="h2"> Credit Card  Reconciliation </h2>
     </div>
     <div>
     <Link to="/solution/license-mgmt">
      <img src={process.env.PUBLIC_URL+'\\LicenseLogo.SVG'} alt="wireless"  />
      </Link> 
      <h2 className="h2">License Key Generator   Expiry Date Manager  </h2>
      </div>
     <div>
      <Link to="/solution/ags-TB-import-tools">
      <img src={process.env.PUBLIC_URL+'\\TBLogo.png'} alt="wireless"   />
      </Link> 
      <h2 className="h2"> Opening Balance   Utilities  </h2>
      </div>
      <div>
      <Link to="/solution/pdftoexcel">
      <img src={process.env.PUBLIC_URL+'\\PDFTOEXCELLOGO.SVG'} alt="wireless"    />
      </Link> 
      <h2 className="h2"> PDF RFP   To Excel List  Summary  </h2>
      </div>
      <div>
      <Link  to="/solution/QuickBase2Sage300">
      <img src={process.env.PUBLIC_URL+'\\QuickBaseLogo.png'} alt="wireless"    />
      </Link> 
      <h2 className="h2">Quick Base   to sage  Integration  </h2>
      </div>
    </div>
  </Zoom>
  )
}

export default LandingPage
