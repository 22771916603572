import React from "react";
import './Contact.css';
import { Zoom } from "react-reveal";
import { message } from "antd";
import {FaFax } from "react-icons/fa"
import {BsSignal, BsWhatsapp, BsTelephone  } from "react-icons/bs"

const axios = require('axios').default;

const Contact=()=>{
 let doSomething = function (e) {
    e.preventDefault();

    let body={
      "senderMail":e.target[1].value,
      "senderName":e.target[2].value,
      "subject":e.target[2].value,
      "message":e.target[3].value
  }
axios.post('https://activatewm.com/v1/email',body).then(res=>{
    console.log(res.data)
    if(res.data.success){
      message.success('Email been sent to AGS team.We will response in a while')
      e.target.reset();
    }else{
      message.error('Email sending failed')
    }
  }).catch(err=>{
    message.error('Email sending failed')
  })
}
    return <div>
    <div className="contact-container">
        <Zoom>
        <div>
            <h1 className="contact_title">Contact Us</h1>
            <p className="contact_paragraph">
             <strong> Sage 300 users/Business Partners:</strong> Contact us using same link below. <br/>
             <strong>Other Enquiries:</strong> Fill in the form below, and we’ll aim to respond within same day to 1 business days. 
            </p>
        </div>
        </Zoom>
        <hr/>
        <div className="contact-div">
          <div className="contact-us-list">
            <Zoom>
          <img className="contactimg" src={process.env.PUBLIC_URL+'\\contact.svg'} alt='AGS' title='AGS' />
          </Zoom>
          <div>
            <strong>
          <Zoom>
            <ul className="contact-list">
           <li> <BsTelephone className="ags_info_icons" /> 1-613-221-5950 Canada </li>
           <li> <BsTelephone className="ags_info_icons" /> 1-917-463-3472 USA/International </li>
            <li> <FaFax className="ags_info_icons" /> FAX - 1-866-898-9826 </li>
           <li> <BsWhatsapp  className="ags_info_icons" /> WhatsApp – available upon request</li>
           <li> <BsSignal  className="ags_info_icons" /> Signal App – available upon request</li>
            </ul>
            </Zoom>  
            </strong>
            </div>
          </div>
       
        <Zoom>
          <div className="contact-form-div">
        <div className="contact_form">
         <form onSubmit={doSomething}>
             <h3 className="form_heading">Enquiry</h3>
             <label>Your Name *</label> <br />
             <input label="Your Name*" name="Name" className="input_field" type="text" placeholder="Name*" required/><br/>
             <label>Your Email *</label> <br />
             <input className="input_field" type="email" placeholder="Email*" required/><br/>
             <label>Subject *</label> <br />
             <input className="input_field" type="subject" placeholder="Subject*" required/><br/>
             <label>Comment *</label> <br />
           <textarea name="message"  placeholder="Please include your massage here*" cols="30" rows="10" /><br/>
           <Zoom>
           <input className="submit_reset_button" type='submit' />
           <input className="submit_reset_button" type="reset" />
           </Zoom>
         </form>
         </div>
         </div>
         </Zoom>
        </div>
       </div>
    </div>
}

export default Contact
