import React, { useEffect, useState } from "react";
import { Button, Image } from "antd";
import { CaretDownOutlined, MenuFoldOutlined } from "@ant-design/icons";
import "./App.css";
import logo from "./logo.png";
import { HashRouter as Router, Route, Link, Routes, useNavigate, Outlet } from "react-router-dom";
import "antd/dist/antd.min.css";
import Solution from "./fragments/Solution";
// import Addons from "./fragments/Addons";
import Contact from "./fragments/Contact";
import About from "./fragments/About";
import Pricing from "./fragments/Pricing";
// import Footer from "./fragments/Footer";
import Downloads from "./fragments/Downloads";
import LandingPage from "./fragments/LandingPage";
import Login from "./fragments/Login";

import { Zoom } from "react-reveal";
let Home=()=>{
  let location=useNavigate()
  return (
      <div>
        <div className="top">
          <div className="header">
           <Link to="/home"> 
           <Image  preview={{ visible: false }}
           src={logo} />
            </Link>
            
            <div className="sponsor-img-div">
          <img src={process.env.PUBLIC_URL+'\\Sponsor-img.png'} className="Sponsor-img" alt="Sage Tech Partner"  />
            </div>
            
            <Zoom>
              <div className="right-div">
                <Button
                  className="download-btn"
                  type="primary"
                  onClick={() => {
                   location('/Downloads')
                  }}
                >
                  Downloads
                </Button>
                <Button
                className="login-btn"
                  type="primary"
                  onClick={() => {
                   location('/Login')
                  }}
                >
                  Logins
                </Button>
              </div>
            </Zoom>
          </div>
        </div>
        <div className="menu">
          <nav>
            <ul className="navigation">
              <li>
                <Link className={"dropdown"} to="#">
                  Solutions
                  <CaretDownOutlined />
                  <ol className="dropdown solution-dropdown">
                    <li>
                      <Link to="/solution/wireless-merchant">
                        Wireless Merchant
                      </Link>
                    </li>
                    <li>
                      <Link to="/solution/credit-rec">Credit Rec </Link>
                    </li>
                    
                    <li>
                      <Link to="/solution/license-mgmt">
                        AGS License Manager
                      </Link>
                    </li>
                   
                  
                    <li>
                      <Link to="/solution/ic-count">IC Count</Link>
                    </li>
                     

                    <li>
                      <Link to="/solution/pdftoexcel">PDF2Excel</Link>
                    </li>
                    <li>
                      <Link to="/solution/QuickBase2Sage300">
                        QuickBase2Sage300
                      </Link>
                    </li>
                    <li>
                      <Link to="/solution/ags-TB-import-tools">
                        AGS TB Import Tools
                      </Link>
                    </li>
                  </ol>
                </Link>
              </li>
              <li>
                <Link to="/home">About Us </Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="/pricing">Pricing</Link>
              </li>
            </ul>
           
          </nav>
        </div>
        <div className="main">
          <Outlet />
        </div>
        
      </div>
  );
}

function App() {
 
 
  return (
    <div className="app">
    <Router>
    <Routes>
      <Route  path="/" element={<Home />} >
      <Route path="/" element={<LandingPage />} />
            <Route
              path="/solution/:solutionID"
              element={<Solution />}
            />
            {/* <Route path="/addons/:addonsID" element={<Addons />} /> */}
            <Route name='contact' path="/contact" element={<Contact />} />
            <Route path="/home" element={<About />} />
            <Route path="/pricing" element={<Pricing />} />
      </Route>
      <Route  path="/Login" element={<Login />} />
      <Route  path="/Downloads" element={<Downloads />} />
      </Routes>
    </Router>  
    </div>
  );
}

export default App;
