import React from 'react';
import { Zoom } from 'react-reveal';
import {Link} from 'react-router-dom';
import './product.css'
 

const Product = () => {
  return (
    <Zoom>
      
      <div className="product-images">
       
          <Link to="/solution/wireless-merchant">
        <img src={process.env.PUBLIC_URL+'\\wireless.png'} alt="wireless" className='WirelessLogoP'  />
        </Link>
        <Link to="/solution/ic-count">
        <img src={process.env.PUBLIC_URL+'\\ICCountLogo.svg'} alt="wireless" className='ICCountLogoP'   />
        </Link>
        <Link to="/solution/credit-rec">
        <img src={process.env.PUBLIC_URL+'\\CreditCardLogo.png'} alt="wireless" className='CreditCardLogoP'   />
        </Link>
        <Link to="/solution/ags-TB-import-tools">
        <img src={process.env.PUBLIC_URL+'\\TBLogo.png'} alt="wireless" className='TBLogoP'   />
        </Link>
        <Link to="/solution/pdftoexcel">
        <img src={process.env.PUBLIC_URL+'\\PDFTOEXCELLOGO.SVG'} alt="wireless" className='PDFTOEXCELLogoP'   />
        </Link>
        <Link  to="/solution/QuickBase2Sage300">
        <img src={process.env.PUBLIC_URL+'\\QuickBaseLogo.png'} alt="wireless" className='QuickBaseLogoP'   />
        </Link>
        <Link to="/solution/license-mgmt">
        <img src={process.env.PUBLIC_URL+'\\LicenseLogo.SVG'} alt="wireless" className='LicenseLogoP'   />
        </Link> 
      </div>
    
    </Zoom>
  )
}

export default Product
