import React from "react";
import './About.css';
import Product from "./Product";
import { Typography } from 'antd';
import { Bounce,  Zoom } from "react-reveal";
import {FaFax } from "react-icons/fa"
import {BsSignal, BsWhatsapp, BsTelephone  } from "react-icons/bs"

const { Title, Text} = Typography;
const About=()=>{

    return<div className="row">
        <Zoom> 
            <div>
               <Product />
            </div>
          <div className="col-1">
           <Typography>
           <Bounce>
           <Title  className="about-heading">
            Welcome To AGS Advanced Software Inc.      
            </Title>
           </Bounce>
             <Text className="col-1-paragrph">
                            <Zoom>
                    <img className="about_img" src={process.env.PUBLIC_URL+'\\about.svg'} alt='AGS' title='AGS' />
                    </Zoom>
                        <p className="about-paragraph">
                    AGS Advanced Software Inc. is a software development house started in 2005 developing 
                    Sage integrated mobile applications.
                    Our flagship product Wireless Merchant opened the doors for easy mobile integration to
                    the back office accounting system. We have evolved 
                    over the years from BlackBerry to iPhone and Android development to
                    desktop and cloud based opportunities. Our software is in countries all 
                    over the world helping businesses simplify their day to day operations. 
                    We continue to grow and expand our team of dedicated staff.
                    </p>
                    </Text>
            </Typography>
            <div className="contactus">
            <img className="contact_img" src={process.env.PUBLIC_URL+'\\contact.svg'} alt='AGS' title='AGS' /> 
        <strong>
            
        <ul className="contact">
        <Zoom >
        
           <li> <BsTelephone className="ags_info_icons" /> 1-613-221-5950 Canada </li>
           <li> <BsTelephone className="ags_info_icons" /> 1-917-463-3472 USA/International </li>
            <li> <FaFax className="ags_info_icons" /> FAX - 1-866-898-9826 </li>
           <li> <BsWhatsapp  className="ags_info_icons" /> WhatsApp – available upon request</li>
           <li> <BsSignal  className="ags_info_icons" /> Signal App – available upon request</li>
            
        </Zoom>
        </ul>
        
        </strong>
        </div>
        </div>
        </Zoom> 
    </div>
    
}
export default About