import React, { useEffect, useState } from 'react'
import './Login.css';
import { message } from 'antd';
import { Zoom } from 'react-reveal';
import axios from 'axios';
import Dashboard from './Dashboard';
import { HomeFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
let data=localStorage.getItem("license-status")
const Login = () => {
  let location=useNavigate()
  let [code,setCode]=useState("")
  let [status,setStatus]=useState(data)
  
  useEffect(()=>{
   if(data){
    setStatus(data)
   }
  },[])
  let verify=(code)=>{
    var data=new FormData();
    data.append('request',
    JSON.stringify({
      "method":"check_license",
      "data":{
          "license_code":`${code}`
      }}))
      axios.post(`https://activatewm.com/api`,
      data
  ).then(response=>{
    if(response.status==200){
      let status=response.data.status
     // setLoading(false)
      if(status!='fail'){   
         if(response.data.data.expire_status==0){
        //  setStatus("Expired")
        //  setExpiry(res.data.data.expire_date)
          message.error("Expired: License code Checked") 
        //  AsyncStorage.setItem('license-code',res.data.data.code)
        //  AsyncStorage.setItem('license-status','Expired')
       //   AsyncStorage.setItem('license-expiry',res.data.data.expire_date,err=>{ 
       //   })   
         } 
         else{
          message.success("Successful: License code Checked") 
         // setStatus(status)
         // setExpiry(res.data.data.expire_date)
          localStorage.setItem('license-status','Success')
          localStorage.setItem('license-expiry',JSON.stringify(response.data))
          setStatus("Success")
                
         }
      }
      else{
      // setStatus(res.data.message)   
     //  AsyncStorage.setItem('license-status',res.data.message)
       message.error(response.data.message) 
      //  setExpiry('')
      //  AsyncStorage.removeItem('license-expiry',err=>{
      //      if(!err){
              
      //      }
      //  })        
      }}
  }).catch(err=>{
    console.log(err)
  }) 

  }
  return (
    <div>
       <h3
                className="back"
                onClick={() => {
                 location('/')
                }}
              >
                <HomeFilled /> Go Back Home </h3>
            
       
      <div className="login_container">
        {status==="Success"?<Dashboard status={setStatus}/>:
         <Zoom>
         <div className="login_form">
             <img src={process.env.PUBLIC_URL+'\\logo.png'} className="login_img" alt="AGS LOGO"  width={400} height={200}/>
             <form>
             <h1>LOGIN</h1>
             <label className="Label">Verification Code *</label> <br />
             <input className="login_input_field" maxLength={8} placeholder="Verification Code*" required
             onChange={(e)=>{
               setCode(e.target.value)
             }}
             /><br/>
             <input  className='login_submit_btn' value={"Submit"} type={"button"} onClick={(data)=>{
                if(code<8){
                 message.warn("please enter complete Code")
                }else{
                 verify(code)
                }
             }}></input> 
             </form>
         </div>
 </Zoom>        
        }
       
      </div>
      
    </div>
  )
}

export default Login
