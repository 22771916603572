import { React, useState } from "react";
import axios from "axios";
import "./Download.css";
import { message, Button, Input } from "antd";
import { HomeFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
const data = [
  {
    SoftwareName: "AGS License Manager",
    DownloadSoftware: "Contact for details",
    DownloadFlyer: "license manager flyer.pdf",
    DownloadGuide: "User Guide",
  },
  {
    SoftwareName: "Credit Rec",
    DownloadSoftware: "Download",
    DownloadFlyer: "credit rec flyer.pdf",
    DownloadGuide: "User Guide",
  },
  {
    SoftwareName: "IC Count",
    DownloadSoftware: "Download",
    DownloadFlyer: "ic count flyer.pdf",
    DownloadGuide: "User Guide",
  },
  {
    SoftwareName: "QuickBase 2 Sage 300",
    DownloadSoftware: "Download",
    DownloadFlyer: "quick base flyer.pdf",
    DownloadGuide: "User Guide",
  },
  {
    SoftwareName: "PDF2Excel",
    DownloadSoftware: "Download",
    DownloadFlyer: "pdf to excel flyer.pdf",
    DownloadGuide: "User Guide",
  },
  {
    SoftwareName: "TB Tools",
    DownloadSoftware: "Contact for details",
    DownloadFlyer: "tb tools flyer.pdf",
    DownloadGuide: "User Guide",
  },
  {
    SoftwareName: "Wireless Merchant Server",
    DownloadSoftware: "Download",
    DownloadFlyer: "wm server processor flyer.pdf",
    DownloadGuide: "User Guide",
  },
  {
    SoftwareName: "Wireless Merchant Web Client ",
    DownloadSoftware: "Include In Software",
    DownloadFlyer: "wm server processor flyer.pdf",
    DownloadGuide: "User Guide",
  },
  {
    SoftwareName: "Wireless Merchant Android Client",
    DownloadSoftware: "Download",
    DownloadFlyer: "wireless merchant flyer.pdf",
    DownloadGuide: "User Guide",
  },
];
Modal.setAppElement(document.getElementById("root"));
function Downloads() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState(null);
  const [key, setKey] = useState(null);
  const [index, setIndex] = useState(null);

  let location = useNavigate();
  let download = (key) => {
    const hide = message.loading("Downloading in progress..", 0);
    axios
      .post(
        "https://activatewm.com/v1/file",
        {
          filename: key,
        },
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", key);
        document.body.appendChild(link);
        link.click();
        hide();
        message.success("Downloading Completed");
      })
      .catch(function (error) {
        console.log(error);
        message.error("Downloading Failed");
      });
  };
  const customStyles = {
    content: {
      top: "40%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: 0,
      transform: "translate(-50%, -50%)",
    },
  };
  function openModal(index2) {
    setKey(data[index2].SoftwareName);
    setIndex(index2);
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    if (email) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        senderMail: email,
        senderName: " ",
        subject: "Download from AGS",
        message: `user of this email trying to download or required info about => ${key}`,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("https://activatewm.com/v1/email", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          message.success("Email Send successfully");
          if ([1, 2, 3, 4, 6, 8].includes(index)) {
            console.log(data[index].SoftwareName)
            switch (data[index].SoftwareName) {
              case "Credit Rec":
              download() 
              break
              case "IC Count":
              download() 
              break
              case "QuickBase 2 Sage 300":
              download() 
              break
              case "Wireless Merchant Server":
              download("WM3.0Server.zip") 
              break
              case "Wireless Merchant Android Client":
              download("wm302.apk") 
              break
            }
          }
        })
        .catch((error) => {
          message.error("Please Try again");
        });
    }
    setIsOpen(false);
  }
  return (
    <div className="downloads">
      <h3
        className="back"
        onClick={() => {
          location("/");
        }}
      >
        <HomeFilled /> Go Back Home{" "}
      </h3>
      {/* Modal Start */}

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <form className="modal">
          <input
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="Please type email here"
            name="email"
            type={"email"}
          />
          <button
            className="btn btn-primary"
            onClick={() => {
              closeModal();
            }}
          >
            Proceed
          </button>
        </form>
      </Modal>

      {/* Modal End */}

      <div className="download_container">
        <table className="download-table">
          <tr>
            <th className="download-th">Software Name</th>
            <th className="download-th">Download Software</th>
            <th className="download-th">Download Flyer</th>
            <th className="download-th">Download Guide</th>
          </tr>
          {data.map((val, key) => {
            return (
              <tr key={key}>
                <td className="download-td">{val.SoftwareName}</td>
                <td className="download-td">
                  <a
                    onClick={() => {
                      openModal(key);
                    }}
                  >
                    {val.DownloadSoftware}
                  </a>
                </td>
                <td className="download-td">
                  <a
                    onClick={() => {
                      download(val.DownloadFlyer);
                    }}
                  >
                    Flyer
                  </a>
                </td>
                <td className="download-td">
                  <a>{val.DownloadGuide}</a>
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
}

export default Downloads;
